<template>
  <div class="business-groth-area default-padding">
    <div class="container">
      <div class="row">
        <div class="col-md-6 default info">
          <h2>BENEFICIOS ADICIONALES</h2>
          <ul>
            <li v-for="(item, index) in items" :key="index">
              {{ index + 1 }}.- {{ item.title }}
            </li>
          </ul>
          <a class="btn btn-theme effect btn-sm" href="/servicio/asesoria-express"
            >Ver Contrato de Servicios</a
          >
        </div>
        <div class="col-md-6">
          <img src="../../assets/img/services/1.jpg" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          title:
            "Resguardo de documentación online y offline del expediente laboral y centro de trabajo.",
        },
        { title: "Reporte mensual." },
        {
          title:
            "Una capacitación trimestral gratuita para ti y tus trabajadores en materia laboral (online o presencial).",
        },
        {
          title:
            "Atención personalizada a través de nuestro call-center disponible de 9:00 - 18:00 hrs. de lunes a viernes.",
        },
        { title: "Buró de trabajador" },
        { title: "Bolsa de trabajo sectorial." },
        {
          title:
            "Elaboración de dictámenes especializados en materia del trabajo.",
        },
        {
          title:
            "Dictamen para deducción de eventualidades y riesgos laborales.",
        },
      ],
    };
  },
};
</script>

<style scoped>
</style>
