<template>
  <div id="services" class="solid-services-area default-padding bottom-30">
    <div class="container">
      <div class="site-heading text-center">
        <div class="row justify-center">
          <div class="col-lg-8">
            <h2>Que incluimos en nuestros servicios</h2>
            <p>
              El servicio “MEL” incluye la realización de la siguiente
              documentación:
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="services-items">
          <div
            v-for="(service, index) in services"
            :key="index"
            class="col-xs-12 col-md-4 equal-height"
          >
            <div class="item">
              <div class="icon">
                <i :class="service.icon"></i>
              </div>
              <div class="info">
                <h5>
                  {{ service.text }}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      services: [
        {
          icon: "fas fa-flask",
          text: "Contrato individual de trabajo por tiempo indeterminado.",
        },
        {
          icon: "fas fa-chart-pie",
          text: "Contrato individual de trabajo por tiempo determinado.",
        },
        {
          icon: "fas fa-users",
          text: "Contrato individual de trabajo por obra.",
        },
        {
          icon: "fas fa-chess",
          text: "Contrato individual de trabajo bajo la modalidad a prueba.",
        },
        {
          icon: "fas fa-chart-line",
          text: "Contrato individual de trabajo bajo la modalidad de capacitación inicial.",
        },
        {
          icon: "fas fa-flask",
          text: "Contrato individual de trabajo para labores discontinuas.",
        },
        {
          icon: "fas fa-flask",
          text: "Contrato individual de trabajo para labores por temporada.",
        },
        {
          icon: "fas fa-chart-pie",
          text: "Comisión Mixta de Seguridad e Higiene.",
        },
        {
          icon: "fas fa-users",
          text: "Comisión Mixta de productividad, capacitación y adiestramiento.",
        },
        {
          icon: "fas fa-chess",
          text: "Comisión Mixta para la participación de los trabajadores en las utilidades de la empresa.",
        },
        {
          icon: "fas fa-chart-line",
          text: "Comisión Mixta para la elaboración del cuadro general de antigüedades.",
        },
        {
          icon: "fas fa-flask",
          text: "Comisión mixta para la elaboración del Reglamento Interior de Trabajo.",
        },
        {
          icon: "fas fa-flask",
          text: "Reglamento Interior de Trabajo. Descripción de puestos.",
        },
        { icon: "fas fa-chart-pie", text: "Organigrama." },
        { icon: "fas fa-users", text: "Acta administrativa de hechos." },
        {
          icon: "fas fa-chess",
          text: "Acta administrativa de resolución de sanción a trabajado.",
        },
        {
          icon: "fas fa-chart-line",
          text: "Acta administrativa de incidencias.",
        },
        {
          icon: "fas fa-flask",
          text: "Solicitud y otorgamiento de vacaciones.",
        },
        {
          icon: "fas fa-flask",
          text: "Solicitud y otorgamiento de permisos con o sin goce de sueldo.",
        },
        {
          icon: "fas fa-chart-pie",
          text: "Convenio individual de confidencialidad.",
        },
      ],
    };
  },
};
</script>

<style scoped>
</style>