<template>
  <div>
    <AboutInfo />

    <ServicesInfo />

    <BusinessGrowth />
  </div>
</template>

<script>
import AboutInfo from "../components/us/AboutInfo.vue";
import BusinessGrowth from "../components/us/BusinessGrowth.vue";
import ServicesInfo from "../components/us/ServicesInfo.vue";
export default {
  components: { AboutInfo, ServicesInfo, BusinessGrowth },
  head: {
    // To use "this" in the component, it is necessary to return the object through a function
    title: function () {
      return {
        inner: "Nosotros",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
</style>