<template>
  <div id="about" class="about-area video-info bg-gray default-padding">

    <div class="container">
      <div class="row">
        <div v-show="showVideo" class="video-overlay-open">
          <a @click="closeVideo" class="close-video">&times;</a>
          <video width="80%" height="600" controls autoplay style="top: 5%; position: absolute">
            <source
                src="../../assets/video/video_enfoque.mp4"
                type="video/mp4"
            />
          </video>
        </div>
        <div class="col-md-6 video-info thumb">
          <img src="../../assets/img/portfolio/5.jpg" alt="Thumb" />
          <div class="overlay-video">
            <a @click="playVideo"  class="popup-youtube video-play-button">
              <i class="fa fa-play"></i>
            </a>
          </div>

        </div>
        <div class="col-md-6 default info">
          <h2>Enfoque Laboral</h2>
          <p>
            Somos una empresa innovadora y de vanguardia con los más altos
            estándares en servicios de asesoría y Defensa Jurídica en Materia de
            Trabajo y con un costo increíblemente accesible, que permite a
            cualquier empresa, independientemente de su tipo o tamaño, estar
            completamente protegidas y contar con asistencia legal personalizada
            para todos sus temas laborales.
          </p>
          <p>
            Con la experiencia profesional de todo nuestro equipo, hemos creado
            una herramienta de asesoría legal-laboral mediante la contratación
            de Membresías Enfoque Laboral (MEL), asesorías que incluyen un call
            center, seguimiento personalizado de un ejecutivo, reporteo
            periódico; las cuales le permitirán a tu empresa contar con toda la
            documentación y asistencia jurídica de carácter preventivo, que en
            materia de trabajo se requiera para cumplir con las leyes y normas
            vigentes al respecto.
          </p>
          <div class="bottom-info">
            <ul>
              <li>
                <router-link
                  :to="{ name: 'Contact' }"
                  class="btn btn-theme effect btn-sm"
                  href="/web/contacto"
                  >Contactanos</router-link
                >
              </li>
              <li><i class="fas fa-phone"></i> +52 33 3121 2647</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      showVideo: false,
    };
  },
  methods: {
    playVideo() {
      this.showVideo = true;
    },
    close(e) {
      if (e.keyCode === 27) {
        this.closeVideo();
      }
    },
    closeVideo() {
      this.showVideo = false;
    },
  },

};
</script>

<style scoped>
.video-overlay-open {
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 1000;
  display: flex;
  justify-content: center;
}

.close-video {
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 30px;
  padding: 10px;
}
</style>
